<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <DatePicker
          range
          v-model="dateRange"
          @confirm="filterApplied"
          @change="filterCancelled"
          confirm
        />
      </div>

      <div class="right-align m-2">
        <div v-if="allReportsLoading">
          <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>
        </div>
        <div v-else>
          <export-excel
            ref="myBtn"
            class="btn btn-primary"
            :fetch="fetchallReports"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="NomadicBookingReports.xls"
          >
            Download Report
          </export-excel>
        </div>
      </div>
    </div>
    <b-table
      striped
      hover
      :fields="fields"
      :busy="isLoading"
      :responsive="true"
      :items="items"
    >
      <template #cell(createdAt)="data">
        <span>
          {{ moment(data.item.createdAt).format("LL") }}
        </span>
      </template>
      <template #cell(booking_date)="data">
        <span>
          {{
            data.item.booking_date &&
            moment(data.item.booking_date).format("LL")
          }}
        </span>
      </template>
      <template #cell(program_id)="data">
        <span>
          <router-link :to="`program/details?id=${data.item.program_id._id}`">
            {{ data.item.program_id.name }}
          </router-link>
        </span>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner type="grow" variant="primary"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-pagination
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    >
    </b-pagination>
  </div>
</template>

<script>
import { booking } from "@/config/api/booking";
import DatePicker from "vue2-datepicker";
import moment from "moment";
export default {
  components: { DatePicker },
  mounted() {
    this.getReports();
  },
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.getReports();
    },
  },
  data() {
    return {
      items: [],
      dateRange: null,
      json_data: [],
      currentPage: 1,
      rows: 2,
      startDate: null,
      endDate: null,
      perPage: 10,
      json_fields: {
        "Reference Number": "reference_number",
        "Booking Date": "booking_date",
        Program: "program_id.name",
        User: "user_id.fullname",
        Vendor: "vendor_id.company_name",
        "Booking Status": "paymentStatus",
      },
      fields: [
        { key: "user_id.fullname", label: "User" },
        { key: "vendor_id.company_name", label: "Vendor" },
        { key: "createdAt", label: "Booked On" },
        { key: "booking_date", label: "Booking Date" },
        { key: "program_id", label: "Program" },
        { key: "paymentStatus", label: "Status" },
      ],
      isLoading: false,
      allReportsLoading: false,
      reportsGenerated: false,
    };
  },

  methods: {
    filterApplied() {
      this.startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      this.endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.getReports();

      // let params = {
      //   startDate: startDate,
      //   endDate: endDate,
      // };
      // this.setDates(params);
      // if (startDate && endDate) {
      //   this.isFilter = true;
      //   this.filtringTable(params).then(() => {
      //     this.isFilter = false;
      //   });
      // }
    },
    filterCancelled() {
      if (this.dateRange[0] == null) {
        this.startDate = null;
        this.endDate = null;
        this.getReports();
      }
    },
    async getReports() {
      this.isLoading = true;
      this.tableData = [];
      const api = booking.fetchReports;
      api.params = { page: this.currentPage, perPage: 10, role: "Normal" };
      if (this.startDate != null && this.endDate != null) {
        api.params.fromDate = this.startDate;
        api.params.toDate = this.endDate;
      }
      await this.generateAPI(api)
        .then((res) => {
          this.items = res.data.data.docs;

          this.rows = res.data.data.totalDocs;
          this.perPage = res.data.data.limit;

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          console.log("Loaded");
        });
    },
    async fetchallReports() {
      this.allReportsLoading = true;
      const api = booking.fetchReports;
      api.params = { all: 1 };
      if (this.startDate != null && this.endDate != null) {
        api.params.fromDate = this.startDate;
        api.params.toDate = this.endDate;
      }
      var res = await this.generateAPI(api);

      this.reportsGenerated = true;
      this.allReportsLoading = false;
      if (res.data.data.length > 0) {
        return res.data.data;
      } else {
        alert("No data found");
        return [];
      }
    },
  },
};
</script>

<style></style>
