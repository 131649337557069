<template>
  <div>
    <Layout>
      <ReportsTable></ReportsTable>
    </Layout>
  </div>
</template>

<script>
import Layout from "@/views/layouts/vertical.vue";
import ReportsTable from "./reports-table.vue";

export default {
  components: { Layout, ReportsTable },
  data() {
    return {};
  },
};
</script>

<style></style>
